export default {
	general: {},
	characters: {
		roxxid: {
			name: 'Роксид',
			excerpt:
				'Роксид — кибер-авантюрист, известный своими смелыми приключениями в виртуальных мирах. Его яркий костюм и высокотехнологичные очки позволяют ему адаптироваться к любым условиям. Всегда в поиске новых вызовов и приключений.',
			desc: '<p><strong>Роксид</strong> — кибер-авантюрист, известный своими смелыми приключениями в виртуальных мирах. Его яркий костюм и высокотехнологичные очки позволяют ему адаптироваться к любым условиям. Всегда в поиске новых вызовов и приключений.</p><p><strong>Раса:</strong> Человек<br><strong>Характер:</strong> Неусидчивый, Умный, Бескорыстный</p>'
		},
		mila: {
			name: 'Мила',
			excerpt:
				'Мила — мечтательная романтичная девушка, которая всегда носит милые вещи. Любит сладкие цвета и мягкие ткани, которые отражают ее добрую и игривую натуру. Всегда готова поддержать друзей и привнести в любой день каплю волшебства и радости.',
			desc: '<p><strong>Мила</strong> — мечтательная романтичная девушка, которая всегда носит милые вещи. Любит сладкие цвета и мягкие ткани, которые отражают ее добрую и игривую натуру. Всегда готова поддержать друзей и привнести в любой день каплю волшебства и радости.</p><p><strong>Раса:</strong> Человек<br><strong>Характер:</strong> Милый, Эмпатичный, Бережливый</p>'
		},
		beak: {
			name: 'Клюв',
			excerpt:
				'Клюв — загадочный воин с острым взглядом, способным разгадывать любые тайны. Его костюмы могут внушать как страх, так и улыбку. Молчалив и серьезен, но когда говорит, его слова всегда полны мудрости и силы.',
			desc: '<p><strong>Клюв</strong> — загадочный воин с острым взглядом, способным разгадывать любые тайны. Его костюмы могут внушать как страх, так и улыбку. Молчалив и серьезен, но когда говорит, его слова всегда полны мудрости и силы.</p><p><strong>Раса:</strong> Птицы<br><strong>Характер:</strong> Загадочный, Справедливый, Верный</p>'
		},
		skeleton: {
			name: 'Скелетон',
			excerpt:
				'Скелетон — живой дух приключений и веселья, всегда готов к действию. Его стильная экипировка сочетается с легким отношением к жизни и смелым духом. Несмотря на пугающую внешность, в нем скрывается легкий характер и желание показать, что смех и отвага всегда идут рука об руку.',
			desc: '<p><strong>Скелетон</strong> — живой дух приключений и веселья, всегда готов к действию. Его стильная экипировка сочетается с легким отношением к жизни и смелым духом. Несмотря на пугающую внешность, в нем скрывается легкий характер и желание показать, что смех и отвага всегда идут рука об руку.</p><p><strong>Раса:</strong> Существа<br><strong>Характер:</strong> Веселый, Добрый, Отважный</p>'
		}
	},
	products: {
		product_1: {
			name: 'Печенье с предсказаниями в брендированной коробке'
		},
		product_2: {
			name: 'Печенье с предсказаниями в брендированной коробке на 6 штук'
		},
		product_3: {
			name: 'Печенье с предсказаниями в брендированной коробке'
		}
	},
	markets: {
		amazon: {
			name: 'Amazon'
		},
		allegro: {
			name: 'Allegro'
		},
		funcookies: {
			name: 'Funcookies'
		},
		wildberries: {
			name: 'Wildberries'
		}
	},
	news: {
		news_1: {
			title: 'MONZI. Встречай своих героев',
			desc: '<p>4 абсолютно уникальных персонажа, каждый со своей историей и особенностями, готовых отправиться в незабываемое приключение в твой мир. Узнай, кто они и как изменят твою реальность!</p>'
		},
		news_2: {
			title: 'Больше MONZI – больше возможностей',
			desc: '<p>Каждый новый герой — это шаг к полной коллекции и новым способностям. Стань одним из первых, собери всех MONZI, обменивайся с друзьями и прокачивай возможности героев на максимум!</p>'
		},
		news_3: {
			title: 'Расскажи свою историю с MONZI: Кто станет твоим фаворитом?',
			desc: '<p>Роксид, Мила, Клюв и Скелетон — четыре эксклюзивных героя, готовых к захватывающим приключениям! Выбери фаворита и делись своими достижениями с друзьями в соцсетях!</p>'
		},
		news_4: {
			title: 'MONZI: Твои герои, твои правила',
			desc: '<p>Все персонажи обладают множеством вариантов внешности и эффектными анимациями, которые ждут тебя. Какой образ ты создашь? Преврати своего MONZI в неповторимого героя, который будет отражать только тебя!</p>'
		}
	},
	product_links: {
		buy_on_amazon: 'Купить на Amazon',
		buy_on_wildberries: 'Купить на Wildberries',
		buy_on_allegro: 'Купить на Allegro',
		buy_on_funcookies: 'Купить на FUNCOOKIES'
	},
	components: {
		app_footer: {},
		app_header: {
			scene: 'Сцена',
			show_qr: 'Показать QR-код'
		},
		app_modal: {
			close: 'Закрыть'
		}
	},
	home_view: {
		home_hero: {
			title: 'Коллекция AR героев MONZI',
			desc: 'Не упустите возможность поиграть в захватывающую игру, которая заставит вас потеряться в мире приключений',
			link: 'Перейти к товарам',
			cards: {
				card_1: {
					title: 'Покажи друзьям',
					desc: 'Снимай AR видео или фото и публикуй их в соцсетях'
				},
				card_2: {
					title: 'Прокачивай своих героев',
					desc: 'Накапливай энергию и используй ее для анимации'
				},
				card_3: {
					title: 'Продавай монзиков',
					desc: 'Не упустите возможность поиграть в захватывающую игру, которая заставит вас потеряться в мире приключений!'
				},
				card_4: {
					title: 'Обменивай персонажей',
					desc: 'Не упустите возможность поиграть в захватывающую игру, которая заставит вас потеряться в мире приключений!'
				}
			}
		},
		home_about: {
			title: 'О ГЕРОЯХ КОЛЛЕКЦИИ',
			subtitle: 'О ПЕРСОНАЖЕ'
		},
		home_how_it_works: {
			title: 'Как это работает',
			cards: {
				card_1: {
					title: 'Покупай печенье',
					desc: 'Купите товар с героем MONZI внутри'
				},
				card_2: {
					title: 'Получи MONZI',
					desc: 'Отсканируй QR код на товаре и получи своего Монзика!'
				},
				card_3: {
					title: 'Собирай коллекцию',
					desc: 'Собери свою уникальную коллекцию и обменивайся монзиками с друзьями'
				}
			}
		},
		home_products: {
			title: 'Товары с монзиками',
			buy_btn: 'Купить'
		},
		home_markets: {
			title: 'Где можно купить'
		},
		home_news: {
			title: 'Новости',
			show_more: 'Показать еще'
		},
		home_faq: {
			title: 'FAQ',
			show_answer: 'Показать ответ',
			card_1: {
				title: 'Как получить анимацию персонажа?',
				desc: 'Приобрести анимацию на персонажа можно за энергию. Энергия накапливается, когда вы кликаете на своего персонажа в разделе “Мои герои”.'
			},
			card_2: {
				title: 'Как мне получить определенного персонажа из печенья?',
				desc: 'Получить определенного персонажа нельзя, все персонажи генерируются случайным образом, и никто не знает, какого персонажа вы получите после сканирования QR-кода. Но вы можете купить персонажа за виртуальную валюту “Печеньки”. Заработать ее вы можете, продавая персонажей вашей коллекции.'
			},
			card_3: {
				title: 'Что я могу делать с персонажами Monzi?',
				desc: 'Вы можете обменивать, дарить, продавать, покупать персонажей Monzi. Также вы можете делать с персонажами фотографии в дополненной реальности и делиться классными снимками в социальных сетях. У вас есть возможность кастомизировать своего Monzi, примеряя на него разные предметы одежды и аксессуары.'
			},
			card_4: {
				title: 'Почему некоторые персонажи Monzi дороже?',
				desc: 'Некоторые персонажи действительно дороже, так как они более редкие и шанс их выпадения ниже.'
			}
		}
	},
	privacy_policy_view: {
		title: 'Политика конфиденциаль&shy;ности',
		content: `<p>Настоящий документ «Политика конфиденциальности» (далее по тексту – «Политика») представляет собой правила использования /указать владельца сайта/ (далее – «мы» и/или «Администрация») данных интернет-пользователей (далее «вы» и/или «Пользователь»), собираемых с использованием сайта / указать URL сайта/ (далее – «Сайт»).</p>
				<h6>1. Обрабатываемые данные</h6>
				<p>1.1. Мы не осуществляем сбор ваших персональных данных с использованием Сайта.</p>
				<p>1.2. Все данные, собираемые на Сайте, предоставляются и принимаются в обезличенной форме (далее – «Обезличенные данные»).</p>
				<p>1.3. Обезличенные данные включают следующие сведения, которые не позволяют вас идентифицировать:</p>
				<p>1.3.1. Информацию, которую вы предоставляете о себе самостоятельно с использованием онлайн-форм и программных модулей Сайта, включая имя и номер телефона и/или адрес электронной почты.</p>
				<p>1.3.2. Данные, которые передаются в обезличенном виде в автоматическом режиме в зависимости от настроек используемого вами программного обеспечения.</p>
				<p>1.4. Администрация вправе устанавливать требования к составу Обезличенных данных Пользователя, которые собираются использованием Сайта.</p>
				<p>1.5. Если определенная информация не помечена как обязательная, ее предоставление или раскрытие осуществляется Пользователем на свое усмотрение. Одновременно вы даете информированное согласие на доступ неограниченного круга лиц к таким данным. Указанные данные становится общедоступными с момента предоставления и/или раскрытия в иной форме.</p>
				<p>1.6. Администрация не осуществляет проверку достоверности предоставляемых данных и наличия у Пользователя необходимого согласия на их обработку в соответствии с настоящей Политикой, полагая, что Пользователь действует добросовестно, осмотрительно и прилагает все необходимые усилия к поддержанию такой информации в актуальном состоянии и получению всех необходимых согласий на ее использование.</p>
				<p>1.7. Вы осознаете и принимаете возможность использования на Сайте программного обеспечения третьих лиц, в результате чего такие лица могут получать и передавать указанные в п.1.3 данные в обезличенном виде.</p>
				<p><strong>Пример!</strong> К указанному программному обеспечению третьих лиц относятся системы сбора статистики посещений Google Analytics и Яндекс.Метрика.</p>
				<p>1.8. Состав и условия сбора обезличенных данных с использованием программного обеспечения третьих лиц определяются непосредственно их правообладателями и могут включать:</p>
				<ul>
				<li>данные браузера (тип, версия, cookie);</li>
				<li>данные устройства и место его положения;</li>
				<li>данные операционной системы (тип, версия, разрешение экрана);</li>
				<li>данные запроса (время, источник перехода, IP-адрес).</li>
				</ul>
				<p>1.9. Администрация не несет ответственность за порядок использования Обезличенных данных Пользователя третьими лицами.</p>
				<h6>2. Цели обработки данных</h6>
				<p>2.1. Администрация использует данные в следующих целях:</p>
				<p>2.1.1. Обработка поступающих запросов и связи с Пользователем;</p>
				<p>2.1.2. Информационное обслуживание, включая рассылку рекламно-информационных материалов;</p>
				<p>2.1.3. Проведение маркетинговых, статистических и иных исследований;</p>
				<p>2.1.4. Таргетирование рекламных материалов на Сайте.</p>
				<h6>3. Требования к защите данных</h6>
				<p>3.1. Администрация осуществляет хранение данных и обеспечивает их охрану от несанкционированного доступа и распространения в соответствии с внутренними правилами и регламентами.</p>
				<p>3.2. В отношении полученных данных сохраняется конфиденциальность, за исключением случаев, когда они сделаны Пользователем общедоступными, а также когда используемые на Сайте технологии и программное обеспечение третьих лиц либо настройки используемого Пользователем программного обеспечения предусматривают открытый обмен с данными лицами и/или иными участниками и пользователями сети Интернет.</p>
				<p>3.3. В целях повышения качества работы Администрация вправе хранить лог-файлы о действиях, совершенных Пользователем в рамках использования Сайта в течение 1 (Одного) года.</p>
				<h6>4. Передача данных</h6>
				<p>4.1. Администрация вправе передать данные третьим лицам в следующих случаях:</p>
				<ul>
				<li>Пользователь выразил свое согласие на такие действия, включая случаи применения Пользователем настроек используемого программного обеспечения, не ограничивающих предоставление определенной информации;</li>
				<li>Передача необходима в рамках использования Пользователем функциональных возможностей Сайта;</li>
				<li>Передача требуется в соответствии с целями обработки данных;</li>
				<li>В связи с передачей Сайта во владение, пользование или собственность такого третьего лица;</li>
				<li>По запросу суда или иного уполномоченного государственного органа в рамках установленной законодательством процедуры;</li>
				<li>Для защиты прав и законных интересов Администрации в связи с допущенными Пользователем нарушениями.</li>
				</ul>
				<h6>Изменение Политики конфиденциальности</h6>
				<p>5.1. Настоящая Политика может быть изменена или прекращена Администрацией в одностороннем порядке без предварительного уведомления Пользователя. Новая редакция Политики вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики.</p>
				<p><strong>Политика разработана <a href="https://monzi.io/">monzi.io</a></strong></p>`
	},
	not_found_view: {
		title: 'Страница не найдена',
		description: 'Упс! Что-то пошло не так, этой страницы не существует',
		btn_text: 'На главную'
	},
	modals: {
		character: {
			title: 'О персонаже:',
			subtitle: 'Купите товар с героем MONZI внутри'
		},
		question_form: {
			successTitle: 'ваш ВОПРОС УСПЕШНО ОТПРАВЛЕН!',
			successDesc:
				'Благодарим за Ваше обращение! Мы в скором времени обязательно ответим Вам на Ваш электронный адресс. Хорошего дня!',
			successButtonText: 'поНЯТНО!',
			errorTitle: 'упс...ПРИ<br />ОТПРАВКЕ<br />ПРОИЗОШЛА<br />ОШИБКА',
			errorDesc:
				'При отправке Вашего сообщения произошла ошибка. Попробуйте пожалуйста позже',
			errorButtonText: 'ПОПРОБОВАТЬ ЕЩЕ'
		}
	},
	forms: {
		question: {
			title: 'остались вопросы?',
			desc: 'Задать вопрос в поддержку',
			email_placeholder: 'Ваш e-mail',
			question_placeholder: 'Ваш вопрос',
			button_text: 'Отправить',
			checkbox_text:
				'Вы даете согласие на обработку Ваших <a href="/privacy-policy">персональных данных</a>',
			link_text: 'политика конфиденциальности'
		}
	},
	footer: {
		title: 'ИНФОРМАЦИЯ О КОМПАНии',
		sng_region_info: 'РБ: ООО «Фабрика желаний», 220024, Минск, Бабушкина 66-17',
		other_region_info:
			'Poland: Fortune cookies Sp. z o.o, 01-171, Warszawa, ul. Młynarska , 42-115'
	},
	header: {
		links: {
			home: 'Главная',
			about: 'О ГЕРОЯХ',
			how_it_works: 'КАК ЭТО РАБОТАЕТ',
			products: 'ТОВАРЫ С МОНЗИ',
			markets: 'ГДЕ КУПИТЬ',
			news: 'НОВОСТИ',
			faq: 'FAQ'
		}
	},
	validator: {
		required: 'Это поле является обязательным.',
		email: 'В этом поле должен быть указан адрес электронной почты.'
	}
}
