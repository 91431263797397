export default {
	general: {},
	characters: {
		roxxid: {
			name: 'Roxid',
			excerpt:
				'Roxid is a cyber adventurer known for his daring adventures in virtual worlds. His colorful costume and high-tech goggles allow him to adapt to any environment. Always on the lookout for new challenges and adventures.',
			desc: '<p><strong>Roxid</strong> is a cyber adventurer known for his daring adventures in virtual worlds. His colorful costume and high-tech goggles allow him to adapt to any environment. Always on the lookout for new challenges and adventures.</p><p><strong>Race:</strong> Human<br><strong>Character:</strong> Reluctant, Intelligent, Selfless</p>'
		},
		mila: {
			name: 'Cutie',
			excerpt:
				'Cutie is a dreamy romantic girl who always wears cute things. Loves sweet colors and soft fabrics that reflect her kind and playful nature. Always ready to support her friends and bring a drop of magic and joy to any day.',
			desc: '<p><strong>Cutie</strong> is a dreamy romantic girl who always wears cute things. Loves sweet colors and soft fabrics that reflect her kind and playful nature. Always ready to support her friends and bring a drop of magic and joy to any day.</p><p><strong>Race:</strong> Human<br><strong>Character:</strong> Sweet, Empathic, Thrifty</p>'
		},
		beak: {
			name: 'Birdman',
			excerpt:
				'Birdman is an enigmatic warrior with a keen eye for solving any mystery. His costumes can inspire both fear and a smile. Silent and serious, but when he speaks, his words are always full of wisdom and power.',
			desc: '<p><strong>Birdman</strong> is an enigmatic warrior with a keen eye for solving any mystery. His costumes can inspire both fear and a smile. Silent and serious, but when he speaks, his words are always full of wisdom and power.</p><p><strong>Race:</strong> Birds<br><strong>Character:</strong> Mysterious, Fair, Loyal</p>'
		},
		skeleton: {
			name: 'Skeleton',
			excerpt:
				'Skeleton is a lively spirit of adventure and fun, always ready for action. His stylish outfit is matched with an easy-going attitude to life and a bold spirit. Despite his intimidating appearance, he has a lighthearted personality and a desire to show that laughter and courage go hand in hand.',
			desc: '<p><strong>Skeleton</strong> is a lively spirit of adventure and fun, always ready for action. His stylish outfit is matched with an easy-going attitude to life and a bold spirit. Despite his intimidating appearance, he has a lighthearted personality and a desire to show that laughter and courage go hand in hand.</p><p><strong>Race:</strong> Creatures<br><strong>Character:</strong> Cheerful, Kind, Brave</p>'
		}
	},
	products: {
		product_1: {
			name: 'Fortune cookies in a branded box'
		},
		product_2: {
			name: 'Fortune cookies in a branded box for 6 pieces'
		},
		product_3: {
			name: 'Fortune cookies in a branded box'
		}
	},
	markets: {
		amazon: {
			name: 'Amazon'
		},
		allegro: {
			name: 'Allegro'
		},
		funcookies: {
			name: 'Funcookies'
		},
		wildberries: {
			name: 'Wildberries'
		}
	},
	news: {
		news_1: {
			title: 'MONZI. Meet your heroes',
			desc: '<p>4 absolutely unique characters, each with their own story and features, ready to embark on an unforgettable adventure in your world. Find out who they are and how they will change your reality!</p>'
		},
		news_2: {
			title: 'More MONZI - more possibilities',
			desc: "<p>Each new hero is a step towards a complete collection and new abilities. Be one of the first to collect all MONZIs, swap with friends and pump up your heroes' abilities to the max!</p>"
		},
		news_3: {
			title: 'Tell your MONZI story: Who will be your favorite?',
			desc: '<p>Roxid, Cutie, Birdman and Skeleton are four exclusive heroes ready for exciting adventures! Pick your favorite and share your progress with your friends on social media!</p>'
		},
		news_4: {
			title: 'MONZI: Your heroes, your rules',
			desc: '<p>All characters have multiple appearance options and spectacular animations waiting for you. Which look will you create? Transform your MONZI into a unique character that will reflect only you!</p>'
		}
	},
	product_links: {
		buy_on_amazon: 'shop on Amazon',
		buy_on_wildberries: 'buy on Wildberries',
		buy_on_allegro: 'buy on Allegro',
		buy_on_funcookies: 'buy on FUNCOOKIES'
	},
	components: {
		app_footer: {},
		app_header: {
			scene: 'Scene',
			show_qr: 'Show QR code'
		},
		app_modal: {
			close: 'Close'
		}
	},
	home_view: {
		home_hero: {
			title: 'MONZI AR Heroes Collection',
			desc: "Don't miss the opportunity to play an exciting game that will make you get lost in a world of adventure",
			link: 'GO TO PRODUCTS',
			cards: {
				card_1: {
					title: 'SHOW YOUR FRIENDS',
					desc: 'Take AR videos or photos and share them on social networks'
				},
				card_2: {
					title: 'PUMP UP YOUR HEROES',
					desc: 'Build up energy and use it for animation'
				},
				card_3: {
					title: 'SELL MONZIES',
					desc: "Don't miss the opportunity to play an exciting game that will make you get lost in a world of adventure"
				},
				card_4: {
					title: 'TRADE CHARACTERS',
					desc: "Don't miss the opportunity to play an exciting game that will make you get lost in a world of adventure"
				}
			}
		},
		home_about: {
			title: 'ABOUT THE HEROES OF THE COLLECTION',
			subtitle: 'ABOUT PERSONAGE'
		},
		home_how_it_works: {
			title: 'HOW IT WORKS',
			cards: {
				card_1: {
					title: 'Buy cookies',
					desc: 'Buy a product with a MONZI character inside'
				},
				card_2: {
					title: 'GET MONZI',
					desc: 'Scan the QR code on the product and get your MONZI!'
				},
				card_3: {
					title: 'COLLECT',
					desc: 'Build your unique collection and exchange monzies with friends'
				}
			}
		},
		home_products: {
			title: 'Products with MONZI',
			buy_btn: 'BUY '
		},
		home_markets: {
			title: 'Where you can buy'
		},
		home_news: {
			title: 'News',
			show_more: 'Show more'
		},
		home_faq: {
			title: 'FAQ',
			show_answer: 'Show answer',
			card_1: {
				title: 'How to get animation on a character?',
				desc: 'You can buy animation on your character for energy. Energy is accumulated if you click on your character in the “My Characters” section'
			},
			card_2: {
				title: 'How do I get a specific character from a cookie?',
				desc: "You can't get a specific character, all characters are generated randomly and no one knows what character you will get after scanning the QR code. But you can buy a character for virtual currency “Cookies”. You can earn it by selling characters from your collection"
			},
			card_3: {
				title: 'What can I do with Monzi characters?',
				desc: 'You can trade, give, sell, buy and trade Monzi characters. You can also take augmented reality photos of your characters and share them on social networks. You have the ability to customize your Monzi by trying on different pieces of clothing and accessories on it'
			},
			card_4: {
				title: 'Why some Monzi characters are more expensive?',
				desc: 'Some characters are actually more expensive because they are rarer and the chance of them falling out is lower'
			}
		}
	},
	privacy_policy_view: {
		title: 'Privacy Policy',
		content: `<p>This document “Privacy Policy” (hereinafter referred to as “Policy”) represents the rules of use of /specify site owner/ (hereinafter referred to as “we” and/or “Administration”) data of Internet users (hereinafter referred to as “you” and/or “User”) collected using the site /specify site URL/ (hereinafter referred to as “Site”).</p>
				<h6>1. Processed data</h6>
				<p>1.1. We do not collect your personal data using the Site.</p>
				<p>1.2. All data collected on the Site is provided and accepted in an anonymized form (“Anonymized Data”).</p>
				<p>1.3. Anonymized Data includes the following information that does not allow you to be identified:</p>
				<p>1.3.1. Information that you provide about yourself using the online forms and program modules of the Site, including your name and telephone number and/or e-mail address.</p>
				<p>1.3.2. Data that is transferred in an anonymized form in automatic mode depending on the settings of the software you use.</p>
				<p>1.4. The Administration has the right to establish requirements for the composition of the User's anonymized data, which are collected by using the Site.</p>
				<p>1.5. If certain information is not marked as mandatory, its provision or disclosure is at the discretion of the User. At the same time you give informed consent to access to such data by an unlimited number of persons. The said data becomes publicly available from the moment of provision and/or disclosure in any other form.</p>
				<p>1.6. The Administration does not verify the accuracy of the data provided and whether the User has the necessary consent for their processing in accordance with this Policy, assuming that the User acts in good faith, prudently and makes all necessary efforts to keep such information up to date and obtain all necessary consents for its use.</p>
				<p>1.7. You realize and accept the possibility of using third party software on the Site, as a result of which such persons may receive and transmit the data specified in clause 1.3 in an anonymized form.</p>
				<p><strong>Example!</strong> The specified third party software includes Google Analytics and Yandex.Metrika systems for collecting visitor statistics.</p>
				<p>1.8. The composition and conditions for the collection of anonymized data using third party software are determined directly by their right holders and may include:</p>
				<ul>
				<li>browser data (type, version, cookie);</li>
				<li>device data and its location</li>
				<li>operating system data (type, version, screen resolution);</li>
				<li>request data (time, source of transition, IP-address).</Li>
				</ul>
				<p>1.9. The Administration is not responsible for the order of use of the User's anonymized data by third parties.</p>
				<h6>2. Purposes of data processing</h6>
				<p>2.1. The Administration uses the data for the following purposes:</p>
				<p>2.1.1. Processing of incoming requests and communication with the User;</p>
				<p>2.1.2. Information service, including sending out advertising and informational materials;</p>
				<p>2.1.3. Carrying out marketing, statistical and other research;</p>
				<p>2.1.4. Targeting of advertising materials on the Website.</p>
				<h6>3. Data protection requirements</h6>
				<p>3.1. The Administration stores data and protects it from unauthorized access and distribution in accordance with internal rules and regulations.</p>
				<p>3.2. The received data shall be kept confidential, except for cases when they are made publicly available by the User, as well as when the technologies and software of third parties used on the Website or the settings of the software used by the User provide for open exchange with these persons and/or other participants and users of the Internet.</p>
				<p>3.3. In order to improve the quality of work, the Administration has the right to store log files on the actions made by the User within the framework of the use of the Site for 1 (One) year.</p>
				<h6>4. Data transfer</h6>
				<p>4.1. The Administration has the right to transfer data to third parties in the following cases:</p>
				<ul>
				<li>The User has expressed its consent to such actions, including cases of application by the User of the settings of the used software, not limiting the provision of certain information;</li>
				<li>Transfer is necessary within the framework of the User's use of the functional capabilities of the Website;</li>
				<li>The transfer is required in accordance with the purposes of data processing;</li>
				<li>In connection with the transfer of the Site into the possession, use or ownership of such third party;</li>
				<li>At the request of a court or other authorized state body within the procedure established by law;</li>
				<li>To protect the rights and legitimate interests of the Administration in connection with violations committed by the User.</li>
				</ul>
				<h6>5. Changing the Privacy Policy</h6>
				<p>5.1. This Policy may be changed or terminated unilaterally by the Administration without prior notice to the User. The new version of the Policy comes into force from the moment of its posting on the Site, unless otherwise provided by the new version of the Policy.</p>
				<p><strong>The Policy was developed by <a href="https://monzi.io/">monzi.io</a></strong></p>`
	},
	not_found_view: {
		title: 'Page not found',
		description: 'Oops! Something went wrong, this page does not exist',
		btn_text: 'Main page'
	},
	modals: {
		character: {
			title: 'ABOUT PERSONAGE',
			subtitle: 'Buy a product with a MONZI character inside'
		},
		question_form: {
			successTitle: 'Your question has been successfully sent!',
			successDesc:
				'Thank you for your inquiry! We will reply to your e-mail address shortly. Have a nice day!',
			successButtonText: 'understandably!',
			errorTitle: 'Oops...there<br />was an error <br />while sending <br />your message',
			errorDesc: 'An error occurred while sending your message. Please try again later',
			errorButtonText: 'Try again'
		}
	},
	forms: {
		question: {
			title: 'Questions?',
			desc: 'Ask support',
			email_placeholder: 'Your E-Mail',
			question_placeholder: 'Your question',
			button_text: 'Send',
			checkbox_text:
				'You agree to the processing of your <a href="/privacy-policy">personal data</a>',
			link_text: 'privacy policy'
		}
	},
	footer: {
		title: 'COMPANY INFORMATION',
		sng_region_info: 'Belarus LLC "Fabrica zhelaniy" 220024, Minsk st. Babushkina 66-17',
		other_region_info:
			'Poland: Fortune cookies Sp. z o.o, 01-171, Warszawa, ul. Młynarska , 42-115'
	},
	header: {
		links: {
			home: 'main',
			about: 'ABOUT THE HEROES',
			how_it_works: 'HOW IT WORKS',
			products: 'Products with MONZI',
			markets: 'WHERE TO BUY',
			news: 'News',
			faq: 'FAQ'
		}
	},
	validator: {
		required: 'This field is mandatory',
		email: 'This field must contain your e-mail address.'
	}
}
